import React from "react";
import { Link } from "react-router-dom";
import "./SupportLandingPage.css";

function SupportLandingPage() {
  return (
    <div>
      <h1>Welcome to Support Landing Page</h1>
      <div className="button-container">
        <Link to="/group3">
          <button>Go to Group 3</button>
        </Link>
        <Link to="/group4">
          <button>Go to Group 4</button>
        </Link>
      </div>
    </div>
  );
}

export default SupportLandingPage;
