import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SupportLandingPage from "./SupportLandingPage";
import Group3Page from "./components/Group3Page";
import Group4Page from "./components/Group4Page";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<SupportLandingPage />} />
          <Route path="/group3" element={<Group3Page />} />
          <Route path="/group4" element={<Group4Page />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
