import React, { useEffect } from "react";

function App() {
  // Function to create a Salesforce case
  const createCaseInSalesforce = () => {
    const caseDetails = {
      subject: "New Support Request",
      description: "Support request created by clicking the button.",
      origin: "Web",
    };

    console.log("Sending case details to Salesforce:", caseDetails);

    fetch(
      "https://blackforce123--c8group3.sandbox.lightning.force.com/services/apexrest/cases/",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(caseDetails),
        mode: "no-cors"
      }
    )
      .then((res) => {
        console.log("Salesforce response status:", res.status);
        if (!res.ok) {
          throw new Error(`Error ${res.status}: ${res.statusText}`);
        }
        return res.json();
      })
      .then((data) =>
        console.log("Case created successfully in Salesforce:", data)
      )
      .catch((error) =>
        console.error("Error creating case in Salesforce:", error)
      );
  };

  useEffect(() => {
    // Dynamically create and add the script for the chatbot
    const script = document.createElement("script");
    script.innerHTML = `
      !function(w, d, s, ...args){
        var div = d.createElement('div');
        div.id = 'aichatbot';
        d.body.appendChild(div);
        w.chatbotConfig = args;
        var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s);
        j.defer = true;
        j.type = 'module';
        j.src = 'https://aichatbot.sendbird.com/index.js';
        f.parentNode.insertBefore(j, f);
      }(window, document, 'script', 'D20E9B89-B22C-476A-974B-23EF9536F266', 'FdHpgLCNfkqnHKbzezS3Z', {
        apiHost: 'https://api-cf-us-3.sendbird.com',
      });
    `;
    document.body.appendChild(script);

    return () => {
      const chatbotDiv = document.getElementById("aichatbot");
      if (chatbotDiv) chatbotDiv.remove();
      script.remove();
    };
  }, []); // Runs only once

  return (
    <div>
      <h1>Welcome to the Support Chat</h1>
      <div id="aichatbot" style={{ marginTop: "20px" }}></div>
    </div>
  );
}

export default App;
